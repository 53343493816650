import httpClient from "@/http-client";

export const get = (options = {}) => {
    return httpClient
        .get("/api/pickup-requests", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const assign = data => {
    return httpClient
        .post("/api/pickup-requests/assign-drivers", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const requestPickupForShop = data => {
    return httpClient
        .post("/api/pickup-requests/shop-requests", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const requestDelivery = data => {
    return httpClient
        .post("/api/pickup-requests", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const cancelRequest = data => {
    return httpClient
        .post("/api/pickup-requests/cancel-requests", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const nearByDrive = data => {
    return httpClient
        .post("/api/pickup-requests/near-by-drivers", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const formModel = (options = {}) => {
    return httpClient
        .get("/api/pickup-requests/form-view-models", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const confirmRequest = data => {
    return httpClient
        .post("/api/pickup-requests/confirm-requests", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const changeServiceType = data => {
    return httpClient
        .post("/api/pickup-requests/change-service-types", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export default {
    get,
    assign,
    requestPickupForShop,
    formModel,
    nearByDrive,
    requestDelivery,
    cancelRequest,
    confirmRequest,
    changeServiceType
};
