import user from "@/api/authorize/user-api";
import {
    transform,
    groupBy,
    uniq,
    map,
    head,
    capitalize,
    sortBy,
    lowerCase,
    split
} from "lodash";

import { baseState, baseMutations } from "@/store/mixins";

const getPermissionOrder = suffix => {
    switch (lowerCase(suffix)) {
        case "view":
            return 0;

        case "create":
            return 1;

        case "update":
            return 2;

        case "delete":
            return 3;

        default:
            return 4;
    }
};

export const state = {
    ...baseState,
    userRole: [],
    formModels: {
        admin: [],
        shop: [],
        driver: [],
        agency: []
    },
    userType: "admin",
    permissions: [],
    searchPermissionText: "",
    disable: false
};
export const getters = {
    permissionGroups: state => {
        const permissions = state.permissions.filter(p => {
            return (
                p.name
                    .toLowerCase()
                    .includes(state.searchPermissionText.toLowerCase()) ||
                (p.group !== null &&
                    p.group
                        .toLowerCase()
                        .includes(state.searchPermissionText.toLowerCase()))
            );
        });

        return transform(
            groupBy(permissions, permission => capitalize(permission.group)),
            (result, value, key) => {
                result[key] = groupBy(
                    sortBy(value, p =>
                        getPermissionOrder(head(p.name.split("-")))
                    ),
                    permission => capitalize(permission.resource)
                );
            },
            {}
        );
    },
    permissionColumns: state => {
        return sortBy(
            uniq(
                map(state.permissions, p => {
                    return capitalize(head(p.name.split("-")));
                })
            ),
            p => getPermissionOrder(p)
        );
    }
};
export const mutations = {
    ...baseMutations,
    RECEIVE_PERMISSIONS(state, permissions) {
        state.permissions = permissions.map(permission => {
            return {
                id: permission.id,
                name: permission.name,
                resource: permission.label,
                group: permission.group
            };
        });
    },
    SET_SEARCH_PERMISSION(state, searchPermissionText) {
        state.searchPermissionText = searchPermissionText;
    },
    SET_USER_ROLE(state, data) {
        state.userRole = data;
    },
    CLEAR_RESOURCES(state) {
        state.userRole = [];
    },
    SET_VIEW_MODELS(state, { data, index }) {
        if (!index) {
            state.formModels = data;
        } else {
            split(index, ",").forEach(el => {
                state.formModels[el] = data[el];
            });
        }
    },
    SET_USER_TYPE(state, value) {
        state.userType = value;
    },
    SET_USER_DISABLE(state, value) {
        state.disable = value;
    }
};
export const actions = {
    fetch(context, attributes = {}) {
        let params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search,
                    user_type: context.state.userType,
                    disable: context.state.disable
                }
            },
            attributes
        );
        return user.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },
    store(context, data) {
        return user.store(data);
    },
    update(context, { id, ...data }) {
        return user.update(id, data);
    },
    changeStatus(context, { id, data }) {
        return user.changeStatus(id, data);
    },
    resetPassword(context, data) {
        return user.resetPassword(data);
    },
    async find(context, id) {
        let response = await user.find(id);
        context.commit("SET_EDIT_DATA", response.data);
    },
    async getUserRole(context, attributes = {}) {
        let response = await user.userRole({
            params: attributes
        });
        context.commit("SET_USER_ROLE", response.data);
    },
    getPermissions(context, attributes) {
        return user
            .permission({
                params: Object.assign({}, attributes)
            })
            .then(response => {
                context.commit("RECEIVE_PERMISSIONS", response.data);
                return Promise.resolve(response);
            });
    },
    getCurrentUser(context, attributes = {}) {
        return user.currentUser({ params: attributes });
    },
    async getFormViewData(context, { params, index }) {
        let response = await user.formModel({ params: params });
        if (response) {
            context.commit("SET_VIEW_MODELS", {
                data: response.data,
                index: index
            });
        }
        return response;
    }
};
