import httpClient from '@/http-client'

export const get = (options = {}) => {
  return httpClient
    .get('/api/branch-profiles', options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const find = (id, options = {}) => {
  return httpClient
    .get(`/api/branch-profiles/${id}`, options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const store = data => {
  return httpClient
    .post('/api/branch-profiles', data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const update = (id, data) => {
  return httpClient
    .put(`/api/branch-profiles/${id}`, data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const destroy = id => {
  return httpClient
    .delete(`/api/branch-profiles/${id}`)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const imageLogo = data => {
    return httpClient
      .post('/api/branch-profiles/logo-images', data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response.data))
}

export const formModel = (options = {}) => {
  return httpClient
    .get('/api/branch-profiles/form-view-models', options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}


export default { get, find, store, update, destroy, imageLogo, formModel }