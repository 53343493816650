export default [
    {
      path: '/admin/roles',
      name: 'role',
      meta: { requiresAuth: true },
      component: () => import('@/pages/auth/role/index.vue')
    },
    {
      path: '/admin/roles/create',
      name: 'role-create',
      meta: { requiresAuth: true },
      component: () => import('@/pages/auth/role/create.vue')
    },
    {
      path: '/admin/roles/:id/edit',
      name: 'role-edit',
      meta: { requiresAuth: true },
      component: () => import('@/pages/auth/role/edit.vue')
    }
  ]
