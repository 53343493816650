import httpClient from "@/http-client";

export const get = (options = {}) => {
    return httpClient
        .get("/api/product-packaging", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const store = data => {
    return httpClient
        .post("/api/product-packaging", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const update = (id, data) => {
    return httpClient
        .put(`/api/product-packaging/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const destroy = (id, data) => {
    return httpClient
        .put(`/api/product-packaging/${id}/delete`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const confirm = (id, data) => {
    return httpClient
        .put(`/api/product-packaging/${id}/confirm`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const convertBooking = data => {
    return httpClient
        .post("/api/product-packaging/convert-to-booking", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const formModel = (options = {}) => {
    return httpClient
        .get("/api/product-packaging/form-view-models", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const confirmDeductStock = id => {
    return httpClient
        .get(`/api/product-packaging/${id}`)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export default {
    get,
    store,
    update,
    destroy,
    confirm,
    convertBooking,
    formModel,
    confirmDeductStock
};
