import httpClient from '@/http-client'

export const get = (options = {}) => {
  return httpClient
    .get('/api/positions', options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const find = (id, options = {}) => {
  return httpClient
    .get(`/api/positions/${id}`, options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const store = data => {
  return httpClient
    .post('/api/positions', data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const update = (id, data) => {
  return httpClient
    .put(`/api/positions/${id}`, data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const destroy = id => {
  return httpClient
    .delete(`/api/positions/${id}`)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export default { 
  get,
  find,
  store,
  update,
  destroy 
}