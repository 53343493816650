import stock from "@/api/inventory/stock-in";
import moment from "moment";

import { baseState, baseMutations } from "@/store/mixins";
import { join, isEmpty, split, includes } from "lodash";

export const state = {
	...baseState,
	formModels: [],
	dateRange: [
		moment()
			.add(-7, "days")
			.format("DD-MM-YYYY"),
		moment().format("DD-MM-YYYY")
	],
	isConfirmedStock: false
};

export const mutations = {
	...baseMutations,
	SET_DATE_RANGE(state, date) {
		state.dateRange = date;
	},
	SET_IS_CONFIRMED_STOCK(state, date) {
		state.isConfirmedStock = date;
	},
	SET_VIEW_MODELS(state, { data, index }) {
		if (!index) {
			state.formModels = data;
		} else {
			split(index, ",").forEach(el => {
				state.formModels[el] = data[el];
			});
		}
	}
};

export const getters = {
	getUom: state => productId => {
		if (state.formModels.product) {
			var product = state.formModels.product.find(
				p => p.product_id == productId
			);
			if (product != undefined) {
				return product.product_uom.map(el => ({
					value: el.uom_id,
					label: el.uom_name_en
				}));
			}
		}
		return [];
	},
	productList: state => ({ chosenProduct, search = "" } = {}) => {
		if (state.formModels.product) {
			if (chosenProduct == undefined) {
				return state.formModels.product.slice(0, 400);
			} else {
				return state.formModels.product
					.filter(
						p =>
							!includes(
								chosenProduct.map(d => d.product_id),
								p.product_id
							)
					)
					.filter(
						p =>
							search
								.split(" ")
								.every(s =>
									p.product_name_en
										.toLowerCase()
										.includes(s.toLowerCase())
								) ||
							search
								.split(" ")
								.every(s =>
									p.barcode
										.toLowerCase()
										.includes(s.toLowerCase())
								)
					)
					.slice(0, 400);
			}
		} else {
			return [];
		}
	}
};

export const actions = {
	fetch(context, attrubutes = {}) {
		let params = Object.assign(
			{
				page: context.state.pagination.page,
				perPage: context.state.pagination.perPage,
				branch_id: context.state.branch_id,
				filter: {
					search: context.state.search,
					date_between: join(context.state.dateRange, ","),
					is_confirm_stock: context.state.isConfirmedStock
				}
			},
			attrubutes
		);
		return stock.get({ params }).then(response => {
			context.commit("RECEIVE_RESOURCES", response);
			return Promise.resolve(response);
		});
	},
	store(context, data) {
		return stock.store(data);
	},
	confirmStock(context, data) {
		return stock.confirmStock(data);
	},
	update(context, { id, data }) {
		return stock.update(id, data);
	},
	async find(context, id) {
		if (isEmpty(context.state.edit_data)) {
			let response = await stock.find(id);
			context.commit("SET_EDIT_DATA", response.data);
		}
	},
	destroy(context, id) {
		return stock.destroy(id);
	},
	async getFormViewData(context, { params, index }) {
		let response = await stock.formModel({ params: params });
		context.commit("SET_VIEW_MODELS", {
			data: response.data,
			index: index
		});
		return Promise.resolve(response);
	}
};
