import driver from '@/api/driver/driver-profile'

import { baseState, baseMutations } from '@/store/mixins'
import { isEmpty } from 'lodash'

export const state = {
  ...baseState,
  isActive: 1,
  is_internal_driver: 1,
  formModels: {},
}

export const getters = {}

export const mutations = {
  ...baseMutations,
  SET_ACTIVE(state, value) {
    state.isActive = value
  },
  SET_IS_INTERNAL(state, value) {
    state.is_internal_driver = value
  },
  SET_VIEW_MODELS(state, { data, index }) {
    if (!index) {
      state.formModels = data
    } else {
      state.formModels[index] = data[index]
    }
  }
}

export const actions = {
  fetch(context, attributes = {}) {
    let params = Object.assign(
      {
        page: context.state.pagination.page,
        perPage: context.state.pagination.perPage,
        filter: {
          search: context.state.search,
          is_active: context.state.isActive,
          is_internal_driver: context.state.is_internal_driver
        }
      },
      attributes
    )
    return driver.get({ params }).then(response => {
      context.commit('RECEIVE_RESOURCES', response)
      return Promise.resolve(response)
    })
  },
  store(context, data) {
    return driver.store(data)
  },
  update(context, { id, data }) {
    return driver.update(id, data)
  },
  async find(context, id) {
    if (isEmpty(context.state.edit_data)) {
      let response = await driver.find(id)
      context.commit('SET_EDIT_DATA', response.data)
    }
  },
  upload(context, data) {
    return driver.uploadImage(data)
  },
  destroy(context, id) {
    return driver.destroy(id)
  },
  async getFormViewData(context, { params, index }) {

    let response = await driver.formModel({ params: params })
    context.commit('SET_VIEW_MODELS', { data: response.data, index: index })
    return response;
  }
}