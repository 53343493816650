import category from '@/api/inventory/product-category'

import { baseState, baseMutations } from '@/store/mixins'

export const state = {
  ...baseState,
  formModels: []
}

export const getters = {}

export const mutations = {
  ...baseMutations,
  SET_VIEW_MODELS(state, {data, index}){
    if(!index){
      state.formModels = data
    }else{
      state.formModels[index] = data[index]
    }
  }
}

export const actions = {
  fetch(context, attributes = {}) {
    let params = Object.assign(
      {
        page: context.state.pagination.page,
        perPage: context.state.pagination.perPage,
        filter: {
          search: context.state.search
        }
      },
      attributes
    )
    return category.get({ params }).then(response => {
      context.commit('RECEIVE_RESOURCES', response)
      return Promise.resolve(response)
    })
  },
  store(context, data) {
    return category.store(data)
  },
  update(context, { id, data }) {
    return category.update(id, data)
  },
  destroy(context, { id }) {
    return category.destroy(id)
  },
  async getFormViewData(context, { params, index }) {
    let response = await category.formModel({ params: params })
    context.commit('SET_VIEW_MODELS', { data: response.data, index: index })
    return response;
  }
}