import income from '@/api/report/income-statement-detail'

import { baseState, baseMutations } from '@/store/mixins'

import moment from 'moment'

export const state = {
  ...baseState,
  formModels: {},
  dateRange: [
		moment().format("DD-MM-YYYY"),
		moment().format("DD-MM-YYYY")
	],
}

export const getters = {}

export const mutations = {
  ...baseMutations,
  SET_DATE_RANGE(state, date) {
		state.dateRange = date;
	}
}

export const actions = {
  fetch(context, attributes = {}) {
    let params = Object.assign(
      {
        date_from: context.state.dateRange[0],
        date_to: context.state.dateRange[1],
      },
      attributes
    )
    return income.get({ params }).then(response => {
      context.commit('RECEIVE_RESOURCES', response)
      return Promise.resolve(response)
    })
  },
  toPdf(context, attributes = {}) {
    let params = Object.assign(
      {
        date_from: context.state.dateRange[0],
        date_to: context.state.dateRange[1],
      },
      attributes
    )
    return income.toPdf({ params })
  }
}