<template>
  <div>
    <iframe id="printf" ref="printf" :src="src"></iframe>
  </div>
</template>

<script>
export default {
  name: 'previewPdfDialog',
  props: ['value', 'src'],
  data() {
    return {}
  },
  methods: {
    preview(){
      
      
      console.log(this.$refs.printf.focus())
      
      // window.frames[0].print()

      // window.frames["printf"].focus();
      // window.frames["printf"].print();
    }
  },
}
</script>

<style scoped>
#container {
  width: 100%;
  height: 70vh;
  background: rgb(93, 94, 95);
  text-align: center;
}
#container iframe {
  width: 100%;
  height: 70vh;
  border: 2px solid #acacac;
}
#container i {
  margin-top: 32vh;
  color: white;
}
</style>
