import httpClient from "@/http-client";

export const get = (options = {}) => {
    return httpClient
        .get("/api/request-deliveries", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const update = (id, data) => {
    return httpClient
        .put(`/api/request-deliveries/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const formModel = (options = {}) => {
    return httpClient
        .get("/api/request-deliveries/form-view-models", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const getDistancePrice = (shopId, serviceTypeId) => {
    return httpClient
        .get(
            `/api/request-deliveries/transportation-fee/${shopId}/${serviceTypeId}`
        )
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const calTransportationFee = (delivery_id, distance_Km) => {
    return httpClient
        .get(
            `/api/request-deliveries/distance-kms/${delivery_id}/${distance_Km}`
        )
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const updateCharge = (id, data) => {
    return httpClient
        .put(`/api/request-deliveries/${id}/charge`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const updateCancelMultiple = data => {
    return httpClient
        .put(`/api/request-deliveries/cancel-multiple-packages`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export default {
    get,
    update,
    formModel,
    getDistancePrice,
    calTransportationFee,
    updateCharge,
    updateCancelMultiple
};
