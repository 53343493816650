<template>
    <div>
        <ol class="breadcrumb float-xl-end">
            <li class="breadcrumb-item"
                v-for="(b, index) in breadcrumb"
                :key="index"
                :class="{ active: b.active }"
            >
                <span v-if="b.active">{{ b.text }}</span>
                <a :href="b.href" @click.prevent="pushRoute(b.href)" v-else>{{
                    b.text
                }}</a>
            </li>
        </ol>
        <h1 class="page-header tw-uppercase">
            {{ title }} <small>{{ subTitle }}</small>
        </h1>
    </div>
</template>

<script>
export default {
    name: 'pageTitle',
    props: {
        title: {
            type: String,
            default: 'Page Title'
        },
        subTitle: {
            type: String,
            default: null
        },
        breadcrumb: {
            type: Array,
            default: function(){
                return []
            }
        }
    },
    methods: {
        pushRoute(path) {
            this.$router.push(path)
        }
    }
}
</script>
