export default [
    {
      path: '/admin/driver/booking-pickups',
      name: 'booking-pickup',
      meta: { requiresAuth: true },
      component: () => import('@/pages/driver/booking-pickup/index.vue')
    },
    {
      path: '/admin/driver/booking-pickups/create',
      name: 'booking-pickup-create',
      meta: { requiresAuth: true },
      component: () => import('@/pages/driver/booking-pickup/create.vue')
    }
  ]
