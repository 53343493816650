export default [
    {
      path: '/admin/agency/driver-assigns',
      name: 'driver-assign',
      meta: { requiresAuth: true },
      component: () => import('@/pages/agency/assign-driver/index.vue')
    },
    {
      path: '/admin/agency/driver-assigns/create',
      name: 'driver-assign-create',
      meta: { requiresAuth: true },
      component: () => import('@/pages/agency/assign-driver/create.vue')
    }
  ]
