export default [
  {
    path: '/admin/finance/global-driver-fees',
    name: 'global-driver-fee',
    meta: { requiresAuth: true },
    component: () => import('@/pages/finance/global-driver-fee/index.vue')
  },
  {
    path: '/admin/finance/global-driver-fees/create',
    name: 'global-driver-fee-create',
    meta: { requiresAuth: true },
    component: () => import('@/pages/finance/global-driver-fee/create.vue')
  },
]
