import httpClient from '@/http-client'

export const get = (options = {}) => {
  return httpClient
    .get('/api/transfers', options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}
export const booking = driverId => {
  return httpClient
    .get( `/api/transfers/${driverId}/booking`)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}


export const store = data => {
  return httpClient
    .post('/api/transfers', data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}


export const formModel = (options = {}) => {
  return httpClient
    .get('/api/transfers/form-view-models', options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}


export default { get, store, booking, formModel }