<template>
  <div class="radio radio-css">
    <input
      type="radio"
      :checked="isChecked"
      :disabled="disabled"
      :value="value"
      :name="name"
      @input="event => onInput(event)"
    />
    <label @click="event => event.target.previousSibling.click()">
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  name: 'TsRadio',
  model: {
    prop: 'checkedValue',
    event: 'input'
  },
  props: {
    value: {},
    checkedValue: {},
    disabled: Boolean,
    name: {
      type: String,
      default: 'radio'
    }
  },
  computed: {
    isChecked() {
      return this.value === true || this.value == this.checkedValue
    }
  },
  methods: {
    onInput() {
      this.$emit('input', this.value)
    }
  }
}
</script>
