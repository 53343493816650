import httpClient from '@/http-client'

export const get = (options = {}) => {
  return httpClient
    .get('/api/request-for-deliveries', options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const requestDelivery = data => {
  return httpClient
    .post('/api/request-for-deliveries', data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const nearByDrive = data => {
  return httpClient
    .post('/api/request-for-deliveries/near-by-drivers', data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const formModel = (options = {}) => {
  return httpClient
    .get('/api/request-for-deliveries/form-view-models', options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export default { get, nearByDrive, requestDelivery, formModel }