export default [
    {
      path: '/admin/driver/driver-profiles',
      name: 'driver-profile',
      meta: { requiresAuth: true },
      component: () => import('@/pages/driver/driver-profile/index.vue')
    },
    {
      path: '/admin/driver/driver-profiles/create',
      name: 'driver-profile-create',
      meta: { requiresAuth: true },
      component: () => import('@/pages/driver/driver-profile/create.vue')
    },
    {
      path: '/admin/driver/driver-profiles/:id/edit',
      name: 'driver-profile-edit',
      meta: { requiresAuth: true },
      component: () => import('@/pages/driver/driver-profile/edit.vue')
    }
  ]
