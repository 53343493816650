export default [
    {
      path: '/admin/finance/transportation-fees',
      name: 'transportation-fee',
      meta: { requiresAuth: true },
      component: () => import('@/pages/finance/transportation-fee/index.vue')
    },
    {
      path: '/admin/finance/transportation-fees/create',
      name: 'transportation-fee-create',
      meta: { requiresAuth: true },
      component: () => import('@/pages/finance/transportation-fee/create.vue')
    },
  ]
