import httpClient from "@/http-client";

export const get = (Options = {}) => {
  return httpClient
    .get("/api/inventory-list", Options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data));
};
export const pdf = (Options = {}) => {
  return httpClient
    .get("/api/inventory-list/pdf", Options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data));
};

export const formModel = (options = {}) => {
  return httpClient
    .get("/api/inventory-list/form-view-models", options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data));
};

export const getPending = (Options = {}) => {
    return httpClient
        .get("/api/inventory-list/pending-views", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const getStockOut = (Options = {}) => {
    return httpClient
        .get("/api/inventory-list/stock-out-views", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export default { get, pdf, formModel, getPending, getStockOut };
