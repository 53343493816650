import httpClient from "@/http-client";

export const get = (Options = {}) => {
    return httpClient
        .get("/api/inventory-pending-list", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const formModel = (options = {}) => {
    return httpClient
        .get("/api/inventory-pending-list/form-view-models", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const preview = (options = {}) => {
    return httpClient
        .get('/api/inventory-pending-list/preview', options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}

export default { get, preview, formModel };
