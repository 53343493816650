export default [
    {
      path: '/admin/agency/profiles',
      name: 'agency-profile',
      meta: { requiresAuth: true },
      component: () => import('@/pages/agency/agency-profile/index.vue')
    },
    {
      path: '/admin/agency/profiles/create',
      name: 'agency-profile-create',
      meta: { requiresAuth: true },
      component: () => import('@/pages/agency/agency-profile/create.vue')
    },
    {
      path: '/admin/agency/profiles/:id/edit',
      name: 'agency-profile-edit',
      meta: { requiresAuth: true },
      component: () => import('@/pages/agency/agency-profile/edit.vue')
    }
  ]
