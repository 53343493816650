import httpClient from '@/http-client'

export const viewData = (options = {}) => {
  return httpClient
    .get('/api/reports/shop-fail-package', options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}
export const toPdf = data => {
  return httpClient
    .post('/api/reports/shop-fail-package/pdf', data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export default { viewData, toPdf }