import httpClient from '@/http-client'


export const get = (options = {}) => {
  return httpClient
    .get('/api/assign-drivers', options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const store = data => {
  return httpClient
    .post('/api/assign-drivers', data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const searchBooking = (options = {}) => {
  return httpClient
    .get('/api/assign-drivers/search-booking', options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const formModel = (options = {}) => {
  return httpClient
    .get('/api/assign-drivers/form-view-models', options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const toPdf = voucherNo => {
  return httpClient
    .get(`/api/assign-drivers/${voucherNo}/pdf`)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export default { get, store, searchBooking, formModel, toPdf }