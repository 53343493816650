export default [
    {
      path: '/admin/agency/shops',
      name: 'shop',
      meta: { requiresAuth: true },
      component: () => import('@/pages/agency/shop/index.vue')
    },
    {
      path: '/admin/agency/shops/create',
      name: 'shop-create',
      meta: { requiresAuth: true },
      component: () => import('@/pages/agency/shop/create.vue')
    },
    {
      path: '/admin/agency/shops/:id/edit',
      name: 'shop-edit',
      meta: { requiresAuth: true },
      component: () => import('@/pages/agency/shop/edit.vue')
    },
    {
      path: '/admin/agency/shops/view-map',
      name: 'shop-map',
      meta: { requiresAuth: true },
      component: () => import('@/pages/agency/shop/map.vue')
    }
  ]
