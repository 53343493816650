export default [
    {
      path: '/admin/finance/company-compensations',
      name: 'company-compensate',
      meta: { requiresAuth: true },
      component: () => import('@/pages/finance/company-compensate/index.vue')
    },
    {
      path: '/admin/finance/company-compensations/create',
      name: 'company-compensate-create',
      meta: { requiresAuth: true },
      component: () => import('@/pages/finance/company-compensate/create.vue')
    },
  ]
