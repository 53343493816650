import driverCompensation from '@/api/finance/driver-compensation'

import moment from 'moment'
import { baseState, baseMutations } from '@/store/mixins'
import { join, split } from 'lodash'

export const state = {
    ...baseState,
    dateRange: [
        moment().format("DD-MM-YYYY"),
        moment().format("DD-MM-YYYY")
    ],
    formModels: {
        driver: []
    },
    is_clear: true
}

export const getters = {}

export const mutations = {
    ...baseMutations,
    SET_DATE_RANGE(state, date) {
        state.dateRange = date;
    },
    SET_VIEW_MODELS(state, { data, index }) {
        if (!index) {
            state.formModels = data
        } else {
            split(index, ',').forEach(el => {
                state.formModels[el] = data[el]
            });
        }
    },
    SET_IS_CLEARED(state, value) {
        state.is_clear = value
    }

}

export const actions = {
    fetch(context, attributes = {}) {
        let params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search,
                    date_between: join(context.state.dateRange, ","),
                    is_driver_clear: context.state.is_clear
                }
            },
            attributes
        )
        return driverCompensation.get({ params }).then(response => {
            context.commit('RECEIVE_RESOURCES', response)
            return Promise.resolve(response)
        })
    },
    detail(context, { driverId, dateRange }) {
        return driverCompensation.detail(driverId, dateRange)
    },
    store(context, data) {
        return driverCompensation.store(data)
    },
    async getFormViewData(context, { params, index }) {
        let response = await driverCompensation.formModel({ params: params })
        if (response) {
            context.commit('SET_VIEW_MODELS', { data: response.data, index: index })
        }
        return response;
    }
}
