import globalDriverFee from '@/api/finance/global-driver-fee'

import { baseState, baseMutations } from '@/store/mixins'

export const state = {
  ...baseState,
  formModels: []
}

export const getters = {}

export const mutations = {
  ...baseMutations,
}

export const actions = {
  fetch(context, attributes = {}) {
    let params = Object.assign(
      {
        page: context.state.pagination.page,
        perPage: context.state.pagination.perPage,
        filter: {
          search: context.state.search
        }
      },
      attributes
    )
    return globalDriverFee.get({ params }).then(response => {
      context.commit('RECEIVE_RESOURCES', response)
      return Promise.resolve(response)
    })
  },
  store(context, data) {
    return globalDriverFee.store(data)
  },
  update(context, { id, data }) {
    return globalDriverFee.update(id, data)
  },
  destroy(context, id) {
    return globalDriverFee.destroy(id)
  },
}