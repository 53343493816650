export default [
    {
      path: '/admin/finance/shop-compensations',
      name: 'shop-compensation',
      meta: { requiresAuth: true },
      component: () => import('@/pages/finance/shop-compensation/index.vue')
    },
    {
      path: '/admin/finance/shop-compensations/create',
      name: 'shop-compensation-create',
      meta: { requiresAuth: true },
      component: () => import('@/pages/finance/shop-compensation/create.vue')
    },
  ]
