import exchange from '@/api/finance/exchange-rate'

import { baseState, baseMutations } from '@/store/mixins'

export const state = {
  ...baseState,
  formModels: {},
}

export const getters = {}

export const mutations = {
  ...baseMutations,
  SET_VIEW_MODELS(state, {data, index}){
    if(!index){
      state.formModels = data
    }else{
      state.formModels[index] = data[index]
    }
  }
}

export const actions = {
  fetch(context, attributes = {}) {
    let params = Object.assign(
      {
        page: context.state.pagination.page,
        perPage: context.state.pagination.perPage,
        filter: {
          search: context.state.search,
          is_active: context.state.isActive
        }
      },
      attributes
    )
    return exchange.get({ params }).then(response => {
      context.commit('RECEIVE_RESOURCES', response)
      return Promise.resolve(response)
    })
  },
  store(context, data) {
    return exchange.store(data)
  },
  update(context, { id, data }) {
    return exchange.update(id, data)
  },
  destroy(context, id) {
    return exchange.destroy(id)
  },
  async getFormViewData(context, { params, control }) {
    let response = await exchange.formModel(params)
    context.commit('SET_VIEW_MODELS', { data: response.data, index: control})
    return response;
  }
}