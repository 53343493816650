<template>
  <th class="with-checkbox ui-checkable tw-border"
    style="font-size: 12px"
  >
    <TsCheckbox
      v-model="$parent.allItemsChecked"
      :disabled="$parent.checkableItems.length === 0"
      @change="$parent.toggleCheckingAllItems()"
    ></TsCheckbox>
  </th>
</template>

<script>
export default {
  name: 'CheckableHead'
}
</script>
