import httpClient from "@/http-client";

export const get = (Options = {}) => {
  return httpClient
    .get("/api/stock-ins", Options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data));
};
export const store = data => {
  return httpClient
    .post("/api/stock-ins", data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data));
};
export const update = (id, data) => {
  return httpClient
    .put(`/api/stock-ins/${id}`, data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data));
};
export const find = (id, options = {}) => {
  return httpClient
    .get(`/api/stock-ins/${id}`, options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
};
export const destroy = id => {
  return httpClient
    .delete(`/api/stock-ins/${id}`)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
};

export const formModel = (options = {}) => {
  return httpClient
    .get("/api/stock-ins/form-view-models", options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data));
};

export const confirmStock = data => {
  return httpClient
    .post("/api/stock-ins/confirm-stocks", data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data));
};

export default { get, store, destroy, update, find, formModel, confirmStock };
