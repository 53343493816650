import Vue from 'vue'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

const requireComponent = require.context('.', true, /Ts[\w]+$/)

requireComponent.keys().forEach(fileName => {
  const componentModule = requireComponent(fileName)

  if (componentModule.default) {
    const componentName = upperFirst(
      fileName
        .replace(/^\.\//, '')
        .replace(/\.\w+$/, '')
        .split(/\//)
        .map(camelCase)
    )

    Vue.component(componentName, componentModule.default)
  } else {
    for (let [componentName, component] of Object.entries(componentModule)) {
      componentName = upperFirst(
        componentName
          .replace(/^\.\//, '')
          .replace(/\.\w+$/, '')
          .split(/\//)
          .map(camelCase)
      )

      Vue.component(componentName, component)
    }
  }
})
