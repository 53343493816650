import historyLog from '@/api/security/history-log'

import { baseState, baseMutations } from '@/store/mixins'
import { join } from 'lodash'
import moment from 'moment'

export const state = {
    ...baseState,
    formModels: {},
    dateRange: [
        moment().add(-7, "days").format("DD-MM-YYYY"),
        moment().format("DD-MM-YYYY")
    ],
}

export const getters = {}

export const mutations = {
    ...baseMutations,
    SET_VIEW_MODELS(state, { data, index }) {
        if (!index) {
            state.formModels = data
        } else {
            state.formModels[index] = data[index]
        }
    },
    SET_DATE_RANGE(state, date) {
        state.dateRange = date;
    },
}

export const actions = {
    fetch(context, attributes = {}) {
        let params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search,
                    date_between: join(context.state.dateRange, ",")
                }
            },
            attributes
        )
        return historyLog.get({ params }).then(response => {
            context.commit('RECEIVE_RESOURCES', response)
            return Promise.resolve(response)
        })
    },

    async getFormViewData(context, { params, index }) {
        let response = await historyLog.formModel({ params: params })
        context.commit('SET_VIEW_MODELS', { data: response.data, index: index })
        return response;
    }
}