import httpClient from '@/http-client'

export const get = (options = {}) => {
  return httpClient
    .get('/api/reports/income-statement-details', options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}
export const toPdf = (options = {}) => {
  return httpClient
    .get('/api/reports/income-statement-details/pdf', options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export default { get, toPdf }