import httpClient from '@/http-client'

export const get = (options = {}) => {
    return httpClient
        .get('/api/driver-compensations', options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}

export const store = data => {
    return httpClient
        .post('/api/driver-compensations', data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}

export const detail = (driverId, data) => {
    return httpClient
        .put(`/api/driver-compensations/${driverId}/details`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}

export const formModel = (options = {}) => {
    return httpClient
        .get('/api/driver-compensations/form-view-models', options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}


export default { get, store, detail, formModel }