import company from '@/api/finance/company-compensate'

import { baseState, baseMutations } from '@/store/mixins'
import { join } from 'lodash'
import moment from 'moment'

export const state = {
  ...baseState,
  dateRange: [
		moment().format("DD-MM-YYYY"),
		moment().format("DD-MM-YYYY")
	]
}

export const getters = {}

export const mutations = {
  ...baseMutations,
  SET_DATE_RANGE(state, date) {
		state.dateRange = date;
	},
}

export const actions = {
  fetch(context, attributes = {}) {
    let params = Object.assign(
      {
        page: context.state.pagination.page,
        perPage: context.state.pagination.perPage,
        filter: {
          search: context.state.search,
          date_between: join(context.state.dateRange, ","),
        }
      },
      attributes
    )
    return company.get({ params }).then(response => {
      context.commit('RECEIVE_RESOURCES', response)
      return Promise.resolve(response)
    })
  },
  store(context, data) {
    return company.store(data)
  },
  driverClearCash() {
    return company.driverClearCash()
  },
  toPdf(context, id) {
    return company.toPdf(id)
  }
}
