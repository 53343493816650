export default [
  {
    path: '/admin/finance/driver-compensations',
    name: 'driver-compensation',
    meta: { requiresAuth: true },
    component: () => import('@/pages/finance/driver-compensation/index.vue')
  },
  {
    path: '/admin/finance/driver-compensations/create',
    name: 'driver-compensation-create',
    meta: { requiresAuth: true },
    component: () => import('@/pages/finance/driver-compensation/create.vue')
  },
]
