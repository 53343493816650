<template>
    <div class="tw-relative" v-bind="$attrs">
        <gmap-autocomplete
						:value="value"
            :placeholder="$t('typeYourLocation')"
            class="form-control"
						style="z-index: 1021;"
            @place_changed="setPlace"
            :select-first-on-enter="true"
            :options="{
                bounds: {
                    north: 12.665678999999999,
                    south: 12.465679,
                    east: 105.09096699999999,
                    west: 104.890967
                },
                fields: ['geometry', 'name']
            }"
        >
            >
        </gmap-autocomplete>
        <span
            @click="getCurrentGeoLocation"
            class="tw-absolute tw-top-0 tw-mt-1 tw-right-2 tw-text-blue-600 tw-cursor-pointer"
            ><i class="fas fa-street-view fa-2x"></i
        ></span>
    </div>
</template>

<script>
export default {
    name: "TsMapAutocomplete",
		event: 'input',
		props: {
			value: {
				type: String,
				default: undefined
			}
		},
    data() {
        return {
            center: {},
						describe: 'Cambodia'
        };
    },
    methods: {
        getCurrentGeoLocation() {
            navigator.geolocation.getCurrentPosition(position => {
                this.$emit("setLocation", {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                });
            });
        },
        setPlace(place) {
            if (!place) return;

            this.$emit("setLocation", {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            });

						this.$emit('input', place.name)
        }
    }
};
</script>

<style lang="css">
	.pac-container {
		z-index: 1023 !important;
	}
	.pac-icon {
    display: none !important;
	}
</style>
