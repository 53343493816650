export default [
    {
      path: '/admin/finance/driver-clear-cashes',
      name: 'driver-clear-cash',
      meta: { requiresAuth: true },
      component: () => import('@/pages/finance/driver-clear-cash/index.vue')
    },
    {
      path: '/admin/finance/driver-clear-cashes/create',
      name: 'driver-clear-cash-create',
      meta: { requiresAuth: true },
      component: () => import('@/pages/finance/driver-clear-cash/create.vue')
    },
  ]
