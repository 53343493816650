function Whois(){
		this.userLocalStorage = JSON.parse(localStorage.getItem("authUser"));
}

Whois.prototype.admin = function() {
	if (this.userLocalStorage) {
			return this.userLocalStorage.user.user_type == "admin";
	}
};
Whois.prototype.shop = function() {
	if (this.userLocalStorage) {
			return this.userLocalStorage.user.user_type == "shop";
	}
};
Whois.prototype.driver = function() {
	if (this.userLocalStorage) {
			return this.userLocalStorage.user.user_type == "driver";
	}
};
Whois.prototype.agency = function() {
	if (this.userLocalStorage) {
			return this.userLocalStorage.user.user_type == "agency";
	}
};


export default {
    install(Vue) {
        Vue.prototype.$whois = new Whois()
    }
};
