<template>
  <div class="pagination" v-if="lastPage > 1">
    <div
      class="page-item previous"
      :class="{ disabled: currentPage == 1 }"
    >
      <a href="#" class="page-link" @click.prevent="previous()">
        Previous
      </a>
    </div>

    <div class="page-item" :class="{ active: 1 == currentPage }">
      <a href="#" class="page-link" @click.prevent="navigate(1)">
        1
      </a>
    </div>
    <div class="page-item disabled" v-if="leftRangePageStart > 2">
      <a
        href="#"
        class="page-link"
        @click.prevent="
          {
          }
        "
      >
        …
      </a>
    </div>
    <div
      class="page-item"
      :class="{ active: page == currentPage }"
      v-for="page in middleRangePages"
      :key="page"
    >
      <a href="#" class="page-link" @click.prevent="navigate(page)">
        {{ page }}
      </a>
    </div>
    <div class="page-item disabled" v-if="RightRangePageEnd < lastPage - 1">
      <a
        href="#"
        @click.prevent="
          {
          }
        "
        >…</a
      >
    </div>
    <div class="page-item" :class="{ active: lastPage == currentPage }">
      <a href="#" class="page-link" @click.prevent="navigate(lastPage)">
        {{ lastPage }}
      </a>
    </div>

    <div
      class="page-item next"
      :class="{ disabled: currentPage == lastPage }"
    >
      <a href="#" class="page-link" @click.prevent="next()">
        Next
      </a>
    </div>
  </div>
</template>

<script>
import { range } from 'lodash'

export default {
  name: 'NavPaging',
  props: {
    lastPage: {
      required: true
    },
    currentPage: {
      required: true
    },
    maxNumberButton: {
      type: Number,
      default: 5
    }
  },
  computed: {
    leftRangePageStart () {
      if (
        this.currentPage < this.maxNumberButton ||
        this.lastPage <= this.maxNumberButton
      ) {
        return 2
      } else if (this.currentPage + this.maxNumberButton > this.lastPage + 1) {
        return this.lastPage - this.maxNumberButton + 1
      }

      return this.currentPage - Math.floor(this.maxNumberButton / 2)
    },
    RightRangePageEnd () {
      if (
        this.currentPage + this.maxNumberButton > this.lastPage + 1 ||
        this.lastPage <= this.maxNumberButton
      ) {
        return this.lastPage - 1
      } else if (this.currentPage < this.maxNumberButton) {
        return this.maxNumberButton
      }

      return this.currentPage + Math.floor(this.maxNumberButton / 2)
    },
    middleRangePages () {
      return range(this.leftRangePageStart, this.RightRangePageEnd + 1)
    }
  },
  methods: {
    navigate (page) {
      if (page == this.currentPage) return

      this.$emit('navigate', page)
    },
    previous () {
      if (this.currentPage - 1 < 1) return

      this.$emit('navigate', this.currentPage - 1)
    },
    next () {
      if (this.currentPage + 1 > this.lastPage) return

      this.$emit('navigate', this.currentPage + 1)
    }
  }
}
</script>
