export default [
    {
      path: '/admin/hr/employee-profiles',
      name: 'employee-profile',
      meta: { requiresAuth: true },
      component: () => import('@/pages/hr/employee-profile/index.vue')
    },
    {
      path: '/admin/hr/employee-profiles/create',
      name: 'employee-profile-create',
      meta: { requiresAuth: true },
      component: () => import('@/pages/hr/employee-profile/create.vue')
    },
    {
      path: '/admin/hr/employee-profiles/:id/edit',
      name: 'employee-profile-edit',
      meta: { requiresAuth: true },
      component: () => import('@/pages/hr/employee-profile/edit.vue')
    }
  ]
