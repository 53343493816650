<template>
    <div style="width:fit-content">
        <input type="file" accept="image/*" ref="file" @change="onFileChange" />
        <div
            class="tw-border tw-rounded-lg tw-p-1 tw-bg-white tw-overflow-hidden tw-relative"
            :style="{ width: `${width}px`, height: `${height}px` }"
            @mouseover="() => (showOverlay = true)"
            @mouseleave="() => (showOverlay = false)"
        >
            <img :src="default_image" alt="" v-if="fileEmpty" />
            <img
                v-else
                :src="imageUrl == '' ? '' : imageUrl"
                class="photo"
                @load="onImageLoaded"
            />

            <div
                class="tw-w-full tw-h-full tw-opacity-30 tw-bg-gray-600 tw-absolute tw-top-0 tw-left-0 tw-transform tw-duration-500"
                v-show="showOverlay"
            ></div>

            <div
                class="tw-w-full tw-h-full tw-absolute tw-top-0 tw-left-0 tw-flex tw-justify-center tw-items-center tw-space-x-3"
                v-show="showOverlay"
            >
                <label
                    @click.prevent="fileInputClick"
                    class="tw-text-white tw-p-2 tw-bg-blue-500 tw-opacity-100 tw-cursor-pointer tw-rounded-lg"
                    ><i class="fas fa-folder"></i></label
                >
                <label
                    @click.prevent="onTakePhoto"
                    class="tw-text-white tw-p-2 tw-bg-red-500 tw-opacity-100 tw-cursor-pointer tw-rounded-lg"
                    ><i class="fas fa-camera-retro"></i></label
                >
            </div>
        </div>

        <!-- crop image -->
        <a-modal
            v-if="show_crop"
            v-model="show_crop"
            :title="$t('crop')"
            centered
            :maskClosable="false"
            @cancel="() => (show_crop = false)"
            :zIndex="1020"
            :footer="null"
            :closable="false"
        >
            <Crop
                v-if="show_crop"
                :width="width"
                :height="height"
                :cropObject="cropObject"
                @close="onCrop"
            />
        </a-modal>

        <!-- take photo -->
        <a-modal
            v-if="take_pic"
            v-model="take_pic"
            :title="$t('takePhoto')"
            centered
            :maskClosable="false"
            @cancel="cancelAndStopCamera"
            :zIndex="1020"
            :footer="null"
        >	
					<div
						v-if="img"
					>
            <Crop
                :width="width"
                :height="height"
                :cropObject="cropObject"
								:takeAgain="true"
                @close="onCrop"
								@retake="() => img = null"
            />
					</div>

					<div class="tw-space-y-2"
						v-show="!img"
					>
            <web-cam
                ref="webcam"
                :device-id="deviceId"
                @started="onStarted"
                @stopped="onStopped"
                @error="onError"
                @notsupported="notSupport"
                @cameras="onCameras"
                @camera-change="onCameraChange"
            />
            <div class="row">
                <div class="col-md-12">
                    <select v-model="camera" class="form-control">
                        <option value="">{{ $t("selectDevice") }}</option>
                        <option
                            v-for="device in devices"
                            :key="device.deviceId"
                            :value="device.deviceId"
                            >{{ device.label }}</option
                        >
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-space-x-3">
                    <ts-button
                        v-tooltip="'Stop camera'"
                        v-if="startCam"
                        color="danger"
                        @click="onStop"
                    >
                        <i class="fas fa-stop"></i>
                    </ts-button>
                    <ts-button
                        v-if="!startCam"
                        v-tooltip="'Start up'"
                        color="info"
                        @click="onStart"
                        class="ml-2"
                    >
                        <i class="fas fa-play"></i>
                    </ts-button>
                    <ts-button
                        v-if="deviceId && startCam"
                        v-tooltip="'Take picture'"
                        color="primary"
                        @click="onCapture"
                        class="ml-2"
                    >
                        <i class="fas fa-camera"></i>
                    </ts-button>
                </div>
            </div>
					</div>
        </a-modal>
    </div>
</template>

<script>
import Crop from "./Crop.vue";
import { WebCam } from "vue-web-cam";
export default {
    components: {
        Crop,
        WebCam
    },
    model: {
        prop: "file",
        event: "input"
    },
    props: {
        file: {
            default: null
        },
        width: {
            type: Number,
            default: 60
        },
        height: {
            type: Number,
            default: 60
        },
        crop: {
            // type: Object,
            default: undefined
        },
        modal: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            fileReader: new FileReader(),
            showOverlay: false,
            cropObject: {},
            imageUrl: "",
            show_crop: false,
            take_pic: false,
            default_image: require("@/assets/default-image.jpg"),

            camera: "",
            deviceId: null,
            img: null,
            devices: [],
            cropped: null,
            startCam: true
        };
    },
    computed: {
        device: function() {
            return this.devices.find(n => n.deviceId === this.deviceId);
        },
        fileEmpty() {
            return (
                this.imageUrl === null ||
                this.imageUrl === undefined ||
                this.imageUrl === ""
            );
        }
    },
    methods: {
        onCapture() {
            this.img = this.$refs.webcam.capture();
            this.cropObject.imageUrl = this.img;
        },
        onStarted() {
            // console.log("On Started Event", stream);
        },
        onStart() {
            this.$refs.webcam.start();
            this.startCam = true;
        },
        onStopped() {
            // console.log("On Stopped Event", stream);
        },
        onStop() {
            this.$refs.webcam.stop();
            this.startCam = false;
        },
				cancelAndStopCamera(){
					this.onStop()
					this.take_pic = false
				},
        onError() {
            // console.log("On Error Event", error);
        },
        notSupport() {
            // console.log(message);
        },
        onCameras(cameras) {
            this.devices = cameras;
        },
        onCameraChange(deviceId) {
            this.deviceId = deviceId;
            this.camera = deviceId;
        },
				onTakePhoto(){
					this.take_pic = true
					this.img = null
				},
        onFileChange(event) {
            let file = event.target.files[0];

            if (file === undefined) return;

            if (!this.validateExtension(file)) {
                this.showInvalideExtensionMessage();
                return;
            }

            this.fileReader.readAsDataURL(file);
            this.emitFileChange(file);
        },
        validateExtension(file) {
            return /(\.jpg|\.jpeg|\.png|\.gif)$/i.exec(file.name);
        },
        showInvalideExtensionMessage() {
            this.$notify({ type: "error", text: "File is not valid" });
        },
        emitFileChange(file) {
            this.$emit("input", file);
            this.$emit("filechange", file);
        },
        fileInputClick() {
            this.$refs.file.click();
        },
        onImageLoaded() {
            // if (this.crop === undefined) return;

            if (
                this.cropObject.canvas === undefined &&
                this.cropObject.imageUrl === undefined
            ) {
                this.cropObject.imageUrl = this.imageUrl;
                this.show_crop = true;
            }
        },
        onCrop() {
            if (
                this.cropObject.canvas === null ||
                this.cropObject.canvas === undefined
            ) {
                this.$refs.file.value = "";
                this.imageUrl = "";
                return;
            }

            this.imageUrl = this.cropObject.canvas.toDataURL();
            this.cropObject.canvas.toBlob(blob => {
                this.emitFileChange(blob);
            });

            this.show_crop = false;
        }
    },
    watch: {
        file: {
            handler() {
                if (typeof this.file === "string") {
                    this.imageUrl = this.file;
                    this.cropObject.imageUrl = this.file;
                }

                this.fileReader.addEventListener(
                    "load",
                    progressEvent => {
                        this.imageUrl = progressEvent.target.result;
                        this.cropObject = {};
                    },
                    false
                );
            },
            immediate: true
        },
        camera: function(id) {
            this.deviceId = id;
        },
        devices: function() {
            // Once we have a list select the first one
            const [first] = this.devices;
            if (first) {
                this.camera = first.deviceId;
                this.deviceId = first.deviceId;
            }
        }
    }
};
</script>

<style lang="scss">
img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    &.photo {
        max-width: 100%;
    }
}

input[type="file"] {
    display: none;
}
</style>
