<template>
  <tr>
    <td class="expandable-column" :colspan="$parent.columnLength + 1">
      <slot />
    </td>
  </tr>
</template>

<script>
export default {
  name: 'ExpandableRow'
}
</script>
