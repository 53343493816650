<style lang="scss" scoped>
.loading-banner-wrapper {
  position: relative;

  .loading-banner {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    left: 0;
    top: 0;
    z-index: 1020;
  }
}
</style>

<template>
  <div class="loading-banner-wrapper tw-w-full tw-h-full">
    <slot></slot>
    <div class="loading-banner" v-show="loading">
      <div class="tw-text-blue-500">
        <span
          class="fas fa-circle-notch fa-spin"
          aria-hidden="true"
          :style="{ 'font-size': fontSize }"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'pt-loading-banner',

  props: {
    loading: {
      type: Boolean,
      default: false
    },

    fontSize: {
      default: '30px'
    }
  }
}
</script>
