export default {
  name: 'TsPanelWrapper',
  functional: true,
  render(h, context) {
    return (
      <div
        {...{
          class: ['tw-p-4', context.data.staticClass, context.data.class],
          style: context.data.staticStyle,
          attrs: context.data.attrs,
          directives: context.data.directives
        }}
      >
        {context.children}
      </div>
    )
  }
}
