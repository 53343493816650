import http from "@/http-client";

export const get = (Options = {}) => {
    return http
        .get("/api/users", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const find = (id, Options = {}) => {
    return http
        .get(`/api/users/${id}`, Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const store = data => {
    return http
        .post("/api/users", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const update = (id, data) => {
    return http
        .put(`/api/users/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const changeStatus = (id, data) => {
    return http
        .put(`/api/users/${id}/change-statuses`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const resetPassword = data => {
    return http
        .put(`/api/admin/reset-password`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const destroy = id => {
    return http
        .delete(`/api/users/${id}`)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const currentUser = (Options = {}) => {
    return http
        .get("/api/current-users", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const formModel = (Options = {}) => {
    return http
        .get("/api/users/form-view-models", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const userRole = (Options = {}) => {
    return http
        .get("/api/users/roles", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const permission = (Options = {}) => {
    return http
        .get("/api/users/permissions", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export default {
    get,
    find,
    store,
    update,
    destroy,
    currentUser,
    userRole,
    permission,
    formModel,
    resetPassword,
    changeStatus
};
