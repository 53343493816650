import shop from '@/api/report/shop-fail-package'

import { baseState, baseMutations } from '@/store/mixins'
import { join } from 'lodash'
import moment from 'moment'

export const state = {
    ...baseState,
    shop_id: undefined,
    dateRange: [
        moment().add(-7, 'day').format("DD-MM-YYYY"),
        moment().format("DD-MM-YYYY")
    ],
}

export const getters = {}

export const mutations = {
    ...baseMutations,
    SET_DATE_RANGE(state, date) {
        state.dateRange = date;
    },
    SET_SHOP_ID(state, date) {
        state.shop_id = date;
    },
}

export const actions = {
    fetch(context, attributes = {}) {
        let params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    shop_id: context.state.shop_id,
                    date_between: join(context.state.dateRange, ","),
                }
            },
            attributes
        )
        return shop.viewData({ params }).then(response => {
            context.commit('RECEIVE_RESOURCES', response)
            return Promise.resolve(response)
        })
    },
    toPdf(context, data) {
        return shop.toPdf(data)
    }
}