import httpClient from '@/http-client'

export const get = (options = {}) => {
  return httpClient
    .get('/api/company-profiles', options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const find = (id, options = {}) => {
  return httpClient
    .get(`/api/company-profiles/${id}`, options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const store = data => {
  return httpClient
    .post('/api/company-profiles', data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const update = (id, data) => {
  return httpClient
    .put(`/api/company-profiles/${id}`, data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const imageLogo = data => {
    return httpClient
      .post('/api/company-profiles/logo-images', data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response.data))
}



export default { get, find, store, update, imageLogo }