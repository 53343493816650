import httpClient from '@/http-client'


export const get = (options = {}) => {
  return httpClient
    .get('/api/driver-drop-packages', options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const store = data => {
  return httpClient
    .post('/api/driver-drop-packages', data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export default { get, store }