import httpClient from '@/http-client'

export const get = (options = {}) => {
  return httpClient
    .get('/api/shops', options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const find = (id, options = {}) => {
  return httpClient
    .get(`/api/shops/${id}`, options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const store = data => {
  return httpClient
    .post('/api/shops', data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const update = (id, data) => {
  return httpClient
    .put(`/api/shops/${id}`, data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const uploadImage = data => {
    return httpClient
      .post('/api/shops/upload-images', data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response.data))
}

export const initStock = shopId => {
    return httpClient
      .get(`/api/shops/${shopId}/init-stocks`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response.data))
}

export const formModel = (options = {}) => {
  return httpClient
    .get('/api/shops/form-view-models', options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const viewOnMap = (options = {}) => {
  return httpClient
      .get("/api/shops/view-on-maps", options)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response.data));
}

export const viewTelegramChatId = (options = {}) => {
  return httpClient
      .get("/api/telegram-chat-id", options)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response.data));
}
export const updateTelegramChatId = data => {
  return httpClient
      .put("/api/shops/update-telegram-chat-id", data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response.data));
}


export default {
    get,
    find,
    store,
    update,
    uploadImage,
    formModel,
    initStock,
    viewOnMap,
    viewTelegramChatId,
    updateTelegramChatId
};