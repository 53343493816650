import bank from '@/api/finance/bank-profile'

import { baseState, baseMutations } from '@/store/mixins'

export const state = {
  ...baseState
}

export const getters = {}

export const mutations = {
  ...baseMutations
}

export const actions = {
  fetch(context, attrubutes = {}) {
    let params = Object.assign(
      {
        page: context.state.pagination.page,
        perPage: context.state.pagination.perPage,
      },
      attrubutes
    )
    return bank.get({ params }).then(response => {
      context.commit('RECEIVE_RESOURCES', response)
      return Promise.resolve(response)
    })
  },
  store(context, data) {
    return bank.store(data)
  },
  update(context, { id, data }) {
    return bank.update(id, data)
  }
}
