var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._b({staticClass:"tw-relative"},'div',_vm.$attrs,false),[_c('gmap-autocomplete',{staticClass:"form-control",staticStyle:{"z-index":"1021"},attrs:{"value":_vm.value,"placeholder":_vm.$t('typeYourLocation'),"select-first-on-enter":true,"options":{
                bounds: {
                    north: 12.665678999999999,
                    south: 12.465679,
                    east: 105.09096699999999,
                    west: 104.890967
                },
                fields: ['geometry', 'name']
            }},on:{"place_changed":_vm.setPlace}},[_vm._v(" > ")]),_c('span',{staticClass:"tw-absolute tw-top-0 tw-mt-1 tw-right-2 tw-text-blue-600 tw-cursor-pointer",on:{"click":_vm.getCurrentGeoLocation}},[_c('i',{staticClass:"fas fa-street-view fa-2x"})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }