import httpClient from '@/http-client'

export const get = (options = {}) => {
    return httpClient
        .get('/api/security/history-log', options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const formModel = (options = {}) => {
    return httpClient
        .get('/api/security/history-log/form-view-models', options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}

export default { get, formModel }