<template>
    <div class="d-flex flex-column h-100">
        <vue-custom-scrollbar
            class="app-content-padding flex-grow-1 overflow-hidden h-100"
        >
            <ts-loading-banner :loading="loading">
                <slot></slot>
            </ts-loading-banner>
        </vue-custom-scrollbar>
        <div
            id="footer"
            class="app-footer m-0 tw-flex tw-justify-end tw-space-x-3 tw-bg-white"
        >
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script>
import AppOptions from "@/config/AppOptions.vue";

export default {
    name: "TsPanelCustomScroll",
    props: {
        loading: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        AppOptions.appContentFullHeight = true;
        AppOptions.appContentClass = "p-0";
    },
    beforeDestroy() {
        AppOptions.appContentFullHeight = false;
        AppOptions.appContentClass = "";
    }
};
</script>
