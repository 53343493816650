import shop from '@/api/report/shop-compensate'

import { baseState, baseMutations } from '@/store/mixins'

export const state = {
  ...baseState,
}

export const getters = {}

export const mutations = {
  ...baseMutations
}

export const actions = {
  fetch(context, data) {
    return shop.viewData(data)
  },
  toPdf(context, data) {
    return shop.toPdf(data)
  }
}