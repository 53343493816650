export default [
    {
        path: "/admin/agency/re-deliveries",
        name: "re-delivery",
        meta: { requiresAuth: true },
        component: () => import("@/pages/agency/re-delivery/index.vue")
    },
    {
        path: "/admin/agency/re-deliveries/create",
        name: "re-delivery-create",
        meta: { requiresAuth: true },
        component: () => import("@/pages/agency/re-delivery/create.vue")
    }
];
