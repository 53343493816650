<template>
    <input 
			@focus="forcusInput"
			type="text" 
			v-bind="$attrs" 
			:value="displayCurrency"
			v-if="!displayFormat"
		/>
		<input 
			type="number" 
			ref="input_num"
			v-bind="$attrs" 
			v-else
			:value="value"
			@blur="loseForcus"
			@input="onInput"
		>
  
</template>

<script>
export default {
	name: 'currencyInput',
	props: {
		value: {
			type: [Number, String],
			default: 0
		},
		currency: {
			type: String,
			default: '$'
		},
		precision: {
			type: Number,
			default: 2
		}
	},
	data() {
		return {
			displayFormat: false
		}
	},
	created() {
		this.displayFormat = true
		this.$nextTick(() => {
				this.displayFormat = false
		})
	},
	computed: {
		displayCurrency() {
			if(typeof this.value == 'number'){
				return this.currency + ' '+ this.$formatNumber(this.value, this.precision)
			}
			return '';
		}
	},
	methods: {
		forcusInput() {
			this.displayFormat = true
			this.$nextTick(() => {
				this.$refs.input_num.focus()
			})
		},
		loseForcus(){
				this.displayFormat = false
		},
		onInput(event){
			let value = event.target.value
			this.$emit('input', value ? parseFloat(value) : 0)
		}
	},
}
</script>