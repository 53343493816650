import cashier from '@/api/report/cash-collection-by-cashier'

import { baseState, baseMutations } from '@/store/mixins'

export const state = {
  ...baseState,
}

export const getters = {}

export const mutations = {
  ...baseMutations
}

export const actions = {
  fetch(context, data) {
    return cashier.viewData(data)
  },
  toPdf(context, data) {
    return cashier.toPdf(data)
  }
}