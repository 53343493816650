<style lang="scss">
.table-col-sort {
  color: #ccc;
  margin-left: 5px;
  &:hover {
    color: #ccc;
  }
  &:focus {
    color: #8e8c8c;
  }
}
</style>

<script>
export default {
  name: 'table-column-filter',
  model: {
    prop: 'sortedColumn',
    event: 'sort'
  },
  props: ['column', 'sortedColumn'],
  data() {
    return {
      order: 0
    }
  },
  computed: {
    sortableColumn() {
      return (
        this.column instanceof Object &&
        this.column.sortKey !== undefined &&
        this.column.sortKey !== null &&
        this.column.sortKey.trim() !== ''
      )
    }
  },
  methods: {
    sort(event) {
      event.preventDefault()

      if (this.order === 0 || this.order === -1) {
        this.order = 1
      } else if (this.order === 1) {
        this.order = -1
      }

      this.$emit('sort', { sortKey: this.column.sortKey, order: this.order })
    }
  },
  watch: {
    sortedColumn() {
      if (!this.sortableColumn || this.sortedColumn === null) return

      if (this.sortedColumn.sortKey !== this.column.sortKey) {
        this.order = 0
      }
    }
  },
  render() {
    if (!this.sortableColumn) return

    return (
      <a href="#" class="table-col-sort tw-text-white" onClick={this.sort}>
        {(() => {
          switch (this.order) {
            case 1:
              return <i class="fas fa-sort-up" />
            case -1:
              return <i class="fas fa-sort-down" />
            default:
              return <i class="fas fa-sort" />
          }
        })()}
      </a>
    )
  }
}
</script>
