import { mapKeys, camelCase, has } from 'lodash'

class Pagination {
  constructor(paging = {}) {
    this.currentPage = paging.currentPage ? parseInt(paging.currentPage) : 1
    this.lastPage = paging.lastPage ? parseInt(paging.lastPage) : 0
    this.perPage = paging.perPage ? parseInt(paging.perPage) : 15
    this.total = paging.total ? parseInt(paging.total) : 0
    this.from = this.perPage * (this.currentPage - 1) + 1
    this.to = this.perPage * this.currentPage
  }
}

export const baseState = {
  resources: [],
  search: null,
  edit_data: [],
  branch_id: [],
  pagination: new Pagination(),
  queryBuilder: {
    include: undefined,
    fields: undefined,
    append: undefined,
    sort: undefined
  }
}

export const baseMutations = {
  RECEIVE_RESOURCES(state, response) {
    state.resources = []
    response.data.forEach(resource => {
      state.resources.push(Object.assign(resource, { _deleting: false }))
    })
    if(has(response, 'meta')){
      state.pagination = new Pagination(mapKeys(response.meta, (v, k) => camelCase(k)))
    }else {
      let { ...meta } = response
      state.pagination = new Pagination(mapKeys(meta, (v, k) => camelCase(k)))
    }
  },
  SET_SEARCH(state, search) {
    if (search === '') state.search = null
    else state.search = search
  },
  RESET_CURRENT_PAGE(state) {
    state.pagination.currentPage = 1
  },
  RESET_PAGINATION(state) {
    state.pagination = new Pagination()
  },
  RESET_STATE(state) {
    state.resources = []
    state.formModels = {}
    state.search = null
    state.pagination = new Pagination()
  },
  SET_EDIT_DATA(state, data) {
    state.edit_data = data
  }
}
