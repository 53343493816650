import drop from '@/api/agency/driver-drop-package'

import { baseState, baseMutations } from '@/store/mixins'
import { join } from 'lodash'
import moment from 'moment'

export const state = {
  ...baseState,
    dateRange: [
        moment().format("DD-MM-YYYY"),
        moment().format("DD-MM-YYYY")
    ],
}

export const getters = {}

export const mutations = {
  ...baseMutations,
  SET_DROP_DATE(state, value){
    state.dateRange = value
  }
}

export const actions = {
    fetch(context, attributes = {}) {
        let params = Object.assign(
        {
            page: context.state.pagination.page,
            perPage: context.state.pagination.perPage,
            filter: {
            search: context.state.search,
            date_between: join(context.state.dateRange, ",")
            }
        },
        attributes
        )
        return drop.get({ params }).then(response => {
        context.commit('RECEIVE_RESOURCES', response)
        return Promise.resolve(response)
        })
    },
    store(context, data) {
        return drop.store(data);
    },
}