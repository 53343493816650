import not from '@/api/notification/notification-template'

import { baseState, baseMutations } from '@/store/mixins'

import { split, isEmpty } from 'lodash'

export const state = {
  ...baseState,
  formModels: {},
}

export const getters = {}

export const mutations = {
  ...baseMutations,
  SET_VIEW_MODELS(state, {data, index}){
    if(!index){
      state.formModels = data
    }else{
      split(index, ',').forEach(el => {
        state.formModels[el] = data[el]
      });
    }
  }
}

export const actions = {
  fetch(context, attributes = {}) {
    let params = Object.assign(
      {
        page: context.state.pagination.page,
        perPage: context.state.pagination.perPage,
        filter: {
          search: context.state.search,
          is_delivery: context.state.isDelivery
        }
      },
      attributes
    )
    return not.get({ params }).then(response => {
      context.commit('RECEIVE_RESOURCES', response)
      return Promise.resolve(response)
    })
  },
  store(context, data) {
    return not.store(data)
  },
  request(context, data) {
    return not.request(data)
  },
  update(context, { id, data }){
    return not.update(id, data)
  },
  async find(context, id) {
    if (isEmpty(context.state.edit_data)) {
      let response = await not.find(id)
      context.commit('SET_EDIT_DATA', response.data)
    }
  },
  destroy(context, id){
    return not.destroy(id)
  },
  async getFormViewData(context, { params, index }) {
    let response = await not.formModel({ params: params })
    context.commit('SET_VIEW_MODELS', { data: response.data, index: index })
    return response;
  }
}