<template>
    <h4
        class="tw-mb-0 tw-p-1 tw-uppercase tw-font-semibold tw-text-gray-400 tw-underline"
        v-bind="$attrs"
    >
        <slot></slot>
    </h4>
</template>

<script>
export default {
    name: "dividerTitle"
};
</script>
