export default [
  {
    path: '/admin/finance/transportation-global-fees',
    name: 'transportation-global-fee',
    meta: { requiresAuth: true },
    component: () => import('@/pages/finance/transportation-global-fee/index.vue')
  },
  {
    path: '/admin/finance/transportation-global-fees/create',
    name: 'transportation-global-fee-create',
    meta: { requiresAuth: true },
    component: () => import('@/pages/finance/transportation-global-fee/create.vue')
  },
]
