export default [
    {
      path: '/admin/notification/notification-templates',
      name: 'notification-template',
      meta: { requiresAuth: true },
      component: () => import('@/pages/notification/notification-template/index.vue')
    },
    {
      path: '/admin/notification/notification-templates/create',
      name: 'notification-template-create',
      meta: { requiresAuth: true },
      component: () => import('@/pages/notification/notification-template/create.vue')
    },
    {
      path: '/admin/notification/notification-templates/:id/edit',
      name: 'notification-template-edit',
      meta: { requiresAuth: true },
      component: () => import('@/pages/notification/notification-template/edit.vue')
    }
  ]
