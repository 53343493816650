import httpClient from '@/http-client'

export const get = (options = {}) => {
    return httpClient
        .get('/api/notification-histories', options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}

export const formModel = (options = {}) => {
    return httpClient
        .get('/api/notification-histories/form-view-models', options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}

export default { get, formModel }