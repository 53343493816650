import httpClient from '@/http-client'

export const viewData = data => {
  return httpClient
    .post('/api/reports/shop-compensate', data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}
export const toPdf = data => {
  return httpClient
    .post('/api/reports/shop-compensate/pdf', data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export default { viewData, toPdf }