import profile from "@/api/inventory/product-profile";

import { baseState, baseMutations } from "@/store/mixins";
import { split } from "lodash";

export const state = {
    ...baseState,
    formModels: []
};

export const getters = {};

export const mutations = {
    ...baseMutations,
    SET_VIEW_MODELS(state, { data, index }) {
        if (!index) {
            state.formModels = data;
        } else {
            split(index, ",").forEach(el => {
                state.formModels[el] = data[el];
            });
        }
    }
};

export const actions = {
    fetch(context, attributes = {}) {
        let params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attributes
        );
        return profile.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },
    store(context, data) {
        return profile.store(data);
    },
    update(context, { id, data }) {
        return profile.update(id, data);
    },
    uploadFile(context, { id, data }) {
        return profile.uploadFile(id, data);
    },
    destroy(context, id) {
        return profile.destroy(id);
    },
    async getFormViewData(context, { params, index }) {
        let response = await profile.formModel({ params: params });
        if (response) {
            context.commit("SET_VIEW_MODELS", {
                data: response.data,
                index: index
            });
        }
        return response;
    }
};
