import req from "@/api/agency/request-for-delivery";

import { baseState, baseMutations } from "@/store/mixins";
import { split } from "lodash";

export const state = {
    ...baseState,
    formModels: []
};

export const getters = {};

export const mutations = {
    ...baseMutations,
    SET_VIEW_MODELS(state, { data, index }) {
        if (!index) {
            state.formModels = data;
        } else {
            split(index, ",").forEach(el => {
                state.formModels[el] = data[el];
            });
        }
    }
};

export const actions = {
    fetch(context, attributes = {}) {
        let params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage
            },
            attributes
        );
        return req.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },
    requestDelivery(context, data) {
        return req.requestDelivery(data);
    },
    nearByDrive(context, data) {
        return req.nearByDrive(data);
    },
    async getFormViewData(context, { params, index }) {
        let response = await req.formModel({ params: params });
        context.commit("SET_VIEW_MODELS", {
            data: response.data,
            index: index
        });
        return response;
    }
};
