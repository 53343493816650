
import { includes } from "lodash";

export default {
    install(Vue) {
        Vue.prototype.$can = function(ability){
			var { permissions } = JSON.parse(localStorage.getItem("authUser"));
			return includes(permissions, ability)
		}
    }
};
