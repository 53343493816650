import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import modules from './modules'


const store = new Vuex.Store({
    state: {
        authUser: null
    },
    getters: {},
    mutations: {
        setAuthUser(state, authUser) {
            if (authUser) {
              state.authUser = Object.assign(
                {},
                {
                  user: authUser.user,
                  permissions: authUser.permissions,
                }
              )
              localStorage.setItem('authUser', JSON.stringify(state.authUser))
            } else {
              state.authUser = null
              localStorage.clear()
            }
        },
    },
    actions: {
        init({ commit }) {
            const authUser = JSON.parse(localStorage.getItem('authUser'))
            if (authUser) {
              commit('setAuthUser', authUser)
            }
        },
    },
    modules
})

store.dispatch('init')

export default store
