import httpClient from '@/http-client'

export const get = (options = {}) => {
  return httpClient
    .get('/api/booking-pickups', options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const store = data => {
  return httpClient
    .post('/api/booking-pickups', data,{
        projection: true 
    })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const update = (id, data) => {
  return httpClient
    .put(`/api/booking-pickups/${id}`, data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const destroy = id => {
  return httpClient
    .delete(`/api/booking-pickups/${id}`)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const uploadImage = data => {
  return httpClient
    .post('/api/booking-pickups/upload-images', data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const formModel = (options = {}) => {
  return httpClient
    .get('/api/booking-pickups/form-view-models', options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const pickupInfo = id => {
  return httpClient
    .get(`/api/booking-pickups/${id}/pickup-info`)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const request = data => {
  return httpClient
    .post('/api/booking-pickups/request-deliveries', data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const getChecked = (options = {}) => {
  return httpClient
    .get('/api/booking-pickups/checked', options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const confirmCheck = data => {
  return httpClient
    .post('/api/booking-pickups/confirm-checked', data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const assingDriver = data => {
  return httpClient
    .post('/api/booking-pickups/assing-drivers', data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const history = id => {
  return httpClient
    .get(`/api/booking-pickups/${id}/histories`)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export default {
  get,
  store,
  destroy,
  update,
  uploadImage,
  formModel,
  request,
  pickupInfo,
  getChecked,
  confirmCheck,
  history,
  assingDriver,
}