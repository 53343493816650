import transfer from '@/api/driver/transfer'

import { baseState, baseMutations } from '@/store/mixins'

import { split, join } from 'lodash'
import moment from 'moment'

export const state = {
  ...baseState,
  formModels: {},
  dateRange: [
		moment().format("DD-MM-YYYY"),
		moment().format("DD-MM-YYYY")
	],
}

export const getters = {}

export const mutations = {
  ...baseMutations,
  SET_DATE_RANGE(state, date) {
		state.dateRange = date;
	},
  SET_VIEW_MODELS(state, {data, index}){
    if(!index){
      state.formModels = data
    }else{
      split(index, ',').forEach(el => {
        state.formModels[el] = data[el]
      });
    }
  }
}

export const actions = {
  fetch(context, attributes = {}) {
    let params = Object.assign(
      {
        page: context.state.pagination.page,
        perPage: context.state.pagination.perPage,
        filter: {
          search: context.state.search,
          date_between: join(context.state.dateRange, ","),
        }
      },
      attributes
    )
    return transfer.get({ params }).then(response => {
      context.commit('RECEIVE_RESOURCES', response)
      return Promise.resolve(response)
    })
  },
  store(context, data) {
    return transfer.store(data)
  },
  booking(context, driverId) {
    return transfer.booking(driverId)
  },
  async getFormViewData(context, { params }) {
    return transfer.formModel({ params: params })
  }
}