import emp from "@/api/hr/employee-profile";

import { baseState, baseMutations } from "@/store/mixins";
import { isEmpty } from "lodash";

export const state = {
    ...baseState,
    formModels: {
        employeeStatus: [],
        position: []
    }
};

export const getters = {};

export const mutations = {
    ...baseMutations,
    SET_VIEW_MODELS(state, { data, index }) {
        if (!index) {
            state.formModels = data;
        } else {
            state.formModels[index] = data[index];
        }
    }
};

export const actions = {
    fetch(context, attributes = {}) {
        let params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search,
                    is_active: context.state.isActive
                }
            },
            attributes
        );
        return emp.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },
    store(context, data) {
        return emp.store(data);
    },
    update(context, { id, data }) {
        return emp.update(id, data);
    },
    async find(context, id) {
        if (isEmpty(context.state.edit_data)) {
            let response = await emp.find(id);
            context.commit("SET_EDIT_DATA", response.data);
        }
    },
    destroy(context, id) {
        return emp.destroy(id);
    },
    upload(context, data) {
        return emp.uploadImage(data);
    },
    async getFormViewData(context, { params, index }) {
        let response = await emp.formModel({ params: params });
        context.commit("SET_VIEW_MODELS", {
            data: response.data,
            index: index
        });
        return response;
    }
};
