import assign from "@/api/agency/assign-driver";

import { baseState, baseMutations } from "@/store/mixins";
import { join } from "lodash";
import moment from "moment";

export const state = {
    ...baseState,
		dateRange: [
				moment().format("DD-MM-YYYY"),
				moment().format("DD-MM-YYYY")
		],
};

export const getters = {};

export const mutations = {
    ...baseMutations,
		SET_DATE_RANGE(state, date) {
			state.dateRange = date;
		},
};

export const actions = {
    fetch(context, attributes = {}) {
        let params = Object.assign(
          {
            page: context.state.pagination.page,
            perPage: context.state.pagination.perPage,
            filter: {
              search: context.state.search,
              date_between: join(context.state.dateRange, ","),
            }
          },
          attributes
        )
        return assign.get({ params }).then(response => {
          context.commit('RECEIVE_RESOURCES', response)
          return Promise.resolve(response)
        })
    },
    store(context, data) {
        return assign.store(data);
    },
    searchBooking(context, params) {
        return assign.searchBooking({ params });
    },
    toPdf(context, voucherNo) {
        return assign.toPdf(voucherNo);
    },
    async getFormViewData(context, { params }) {
       return await assign.formModel({ params: params });
    }
};
