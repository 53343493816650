export default [
	{
		path: "/admin/inventory/stock-ins",
		name: "stock-in",
		meta: { requiresAuth: true },
		component: () => import("@/pages/inventory/stock-in/index.vue")
	},
	{
		path: "/admin/inventory/stock-ins/create",
		name: "stock-in-create",
		meta: { requiresAuth: true },
		component: () => import("@/pages/inventory/stock-in/create.vue")
	},
	{
		path: "/admin/inventory/stock-ins/:id/edit",
		name: "stock-in-edit",
		meta: { requiresAuth: true },
		component: () => import("@/pages/inventory/stock-in/edit.vue")
	}
];
