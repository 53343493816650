import QueryString from "qs";
import VueCookies from "vue-cookies";
const axios = require("axios");

// Map to store cancel tokens for specific URLs
const cancelTokenMap = {};

const http = axios.create({
    baseURL: null,
    headers: {
        "X-Requested-With": "XMLHttpRequest"
    },
    paramsSerializer: params => {
        return QueryString.stringify(params, { arrayFormat: "brackets" });
    }
});

http.interceptors.request.use(
    config => {
        const { url, projection } = config;
        // Check if cancel token exists for this URL
        if (cancelTokenMap[url]) {
            // Cancel previous request for this URL
            cancelTokenMap[url].cancel("Request canceled due to new request");
        }

        if(projection) {
            // Create new cancel token for the current request
            const cancelTokenSource = axios.CancelToken.source();
            cancelTokenMap[url] = cancelTokenSource;
    
            config.cancelToken = cancelTokenSource.token;
        }

        const token = VueCookies.get("token");

        if (token) {
            config.headers = Object.assign(config.headers, {
                Authorization: `Bearer ${token}`
            });
        }
        return config;
    },
    error => Promise.reject(error)
);

export default http;
