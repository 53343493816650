<template>
  <td class="with-checkbox ui-checkable">
    <TsCheckbox
      v-model="$parent.checkedItems"
      :value=" checkBoxKeyValue ? record[checkBoxKeyValue] : record"
      :disabled="$parent.checkableItems.length === 0"
      @change="$parent.checkItem()"
    ></TsCheckbox>
  </td>
</template>

<script>
export default {
  name: 'CheckableColumn',
  props: ['record', 'checkBoxKeyValue']
}
</script>
