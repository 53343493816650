<script>
const sidebarMenu = [
    //   { path: '/dashboard', icon: 'fa fa-th', title: 'menu.dashboard',
    //     children: [
    //       { path: '/dashboard/v1', title: 'menu.dashboard1' },
    //       { path: '/dashboard/v2', title: 'menu.dashboard2' },
    //       { path: '/dashboard/v3', title: 'menu.dashboard3' }
    //     ]
    //   },
    {
        icon: 'fa fa-th',
        path: '/admin/dashboard',
        title: 'menu.dashboard'
    },
    {
        icon: 'fab fa-acquisitions-incorporated',
        title: 'menu.corporate',
        children: [
            {
                path: '/admin/corporate/company-profiles',
                title: 'menu.companyProfile',
                permission: 'view-company-profiles'
            },
            {
                path: '/admin/agency/profiles',
                title: 'menu.agencyProfile',
                permission: 'view-agency-profiles'
            },
            {
                path: '/admin/finance/promotions',
                title: 'menu.promotion'
            },
            {
                path: '/admin/corporate/front-covers',
                title: 'menu.frontCover'
            },
            {
                path: '/admin/hr/holiday-rules',
                title: 'menu.holidayRule'
            },
            {
                path: '/admin/hr/holiday-lists',
                title: 'menu.holidayList'
            }
        ]
    },
    {
        icon: 'fas fa-cog',
        path: '/admin/corporate/settings',
        title: 'menu.setting'
    },
    {
        icon: 'fas fa-percentage',
        path: '/admin/finance/exchange-rates',
        title: 'menu.exchangeRate',
        permission: 'view-exchange-rates'
    },
    {
        icon: 'fas fa-bell',
        title: 'menu.notification',
        children: [
            {
                path: '/admin/notification/notification-templates',
                title: 'menu.notificationTemplate',
                permission: 'view-notification-templates'
            },
            {
                path: '/admin/notification/schedules',
                title: 'menu.schedules'
            },
            {
                path: '/admin/notification/announcements',
                title: 'menu.announcement'
            },
            {
                path: '/admin/notification/monitoring',
                title: 'menu.monitoring'
            },
            {
                path: '/admin/notification/notification-histories',
                title: 'menu.notificationHistory'
            }
        ]
    },
    {
        icon: 'fas fa-user-alt',
        title: 'menu.employeeProfile',
        children: [
            {
                path: '/admin/hr/positions',
                title: 'menu.position',
                permission: 'view-positions'
            },
            {
                path: '/admin/hr/divisions',
                title: 'menu.division'
            },
            {
                path: '/admin/hr/sections',
                title: 'menu.section'
            },
            {
                path: '/admin/hr/attendance-shifts',
                title: 'menu.attendanceShift'
            },
            {
                path: '/admin/hr/work-schedules',
                title: 'menu.workSchedule'
            },
            {
                path: '/admin/hr/employee-profiles',
                title: 'menu.profile',
                permission: 'view-employee-profiles'
            },
            {
                path: '/admin/hr/card-activators',
                title: 'menu.cardActivator'
            },
            {
                path: '/admin/hr/leave-records',
                title: 'menu.leaveRecord'
            },
            {
                path: '/admin/hr/daily-time-scans',
                title: 'menu.dailyTimeScan'
            }
        ]
    },
    {
        icon: 'fas fa-user-cog',
        title: 'menu.setup',
        children: [
            {
                path: '/admin/agency/shops',
                title: 'menu.shop',
                permission: 'view-shop-profiles'
            },
            {
                path: '/admin/driver/driver-profiles',
                title: 'menu.driverProfile',
                permission: 'view-driver-profiles'
            }
        ]
    },
    {
        icon: 'fas fa-dollar-sign',
        title: 'menu.finance',
        children: [
            {
                path: '/admin/finance/transportation-global-fees',
                title: 'menu.transportationGlobalFee',
                permission: 'view-transportation-global-fees'
            },
            {
                path: '/admin/finance/global-driver-fees',
                title: 'menu.globalDriverFee',
                permission: 'view-global-driver-fees'
            },
            {
                path: '/admin/finance/transportation-fees',
                title: 'menu.transportationFee',
                permission: 'view-transportation-fees'
            },
            {
                path: '/admin/finance/bank-profiles',
                title: 'menu.bankProfile',
                permission: 'view-bank-profiles'
            },
            {
                path: '/admin/finance/driver-clear-cashes',
                title: 'menu.driverClearCash',
                permission: 'view-driver-clear-cashes'
            },
            {
                path: '/admin/finance/shop-compensations',
                title: 'menu.shopCompensation',
                permission: 'view-shop-compensations'
            },
            {
                path: '/admin/finance/driver-compensations',
                title: 'menu.driverCompensation',
                permission: 'view-driver-compensations'
            },
            {
                path: '/admin/finance/company-compensations',
                title: 'menu.companyCompensation',
                permission: 'view-company-compensates'
            }
        ]
    },
    {
        icon: 'fas fa-truck',
        title: 'menu.operation',
        children: [
            {
                path: '/admin/agency/pickup-requests',
                title: 'menu.pickupRequest',
                permission: 'view-pickup-requests'
            },
            {
                path: '/admin/driver/booking-pickups',
                title: 'menu.bookingPickup',
                permission: 'view-booking-pickups'
            },
            {
                path: '/admin/agency/request-for-deliveries',
                title: 'menu.requestForDelivery',
                permission: 'view-request-for-deliveries'
            },
            {
                path: '/admin/agency/driver-assigns',
                title: 'menu.driverAssign',
                permission: 'view-assign-drivers'
            },
            {
                path: '/admin/agency/request-deliveries',
                title: 'menu.requestDelivery',
                permission: 'view-request-deliveries'
            },
            {
                path: '/admin/agency/re-deliveries',
                title: 'menu.redelivery',
                permission: 'view-pending-histories'
            },
            {
                path: '/admin/agency/drops',
                title: 'menu.drop',
                permission: 'view-driver-drop-packages'
            },
            {
                path: '/admin/driver/transfers',
                title: 'menu.transfer',
                permission: 'view-transfers'
            },
            {
                path: '/admin/agency/delivery-monitoring',
                title: 'menu.deliveryMonitoring'
            }
        ]
    },
    {
        icon: 'fas fa-folder',
        title: 'menu.inventory',
        children: [
            {
                title: 'menu.product',
                children: [
                    {
                        path: '/admin/inventory/product-categories',
                        title: 'menu.productCategory',
                        permission: 'view-product-categories'
                    },
                    {
                        path: '/admin/inventory/unit-of-measures',
                        title: 'menu.unitOfMeasure',
                        permission: 'view-unit-of-measures'
                    },
                    {
                        path: '/admin/inventory/product-profiles',
                        title: 'menu.productProfile',
                        permission: 'view-product-profiles'
                    }
                ]
            },
            {
                path: '/admin/inventory/stock-ins',
                title: 'menu.stockIn',
                permission: 'view-stock-ins'
            },
            {
                path: '/admin/inventory/product-packaging',
                title: 'menu.productPackaging',
                permission: 'view-product-packaging'
            },
            {
                path: '/admin/inventory/inventory-lists',
                title: 'menu.inventoryList',
                permission: 'view-inventory-lists'
            },
            {
                path: '/admin/inventory/inventory-pending-lists',
                title: 'menu.inventoryPendingList',
                permission: 'view-inventory-pending-lists'
            }
        ]
    },
    {
        icon: 'fas fa-file-alt',
        title: 'menu.report',
        children: [
            {
                title: 'menu.finance',
                children: [
                    {
                        path: '/admin/reports/fee-list',
                        title: 'menu.feeList'
                    },
                    {
                        path: '/admin/reports/shop-compensations',
                        title: 'menu.shopCompensation',
                        permission: 'view-shop-compensates'
                    },
                    {
                        path: '/admin/reports/shop-fail-packages',
                        title: 'menu.shopFailPackage',
                        permission: 'view-shop-fail-packages'
                    },
                    {
                        path: '/admin/reports/driver-compensations',
                        title: 'menu.driverCompensation'
                    },
                    {
                        path: '/admin/reports/agent-compensations',
                        title: 'menu.agentCompensation'
                    },
                    {
                        path: '/admin/reports/finances/cash-collect-by-cashiers',
                        title: 'menu.cashCollectByCashier',
                        permission: 'view-cash-collect-by-cashiers'
                    },
                    {
                        path: '/admin/reports/finances/income-statement-details',
                        title: 'menu.incomeStatementDetail',
                        permission: 'view-income-statement-details'
                    },
                    {
                        path: '/admin/reports/finances/driver-transaction-summaries',
                        title: 'menu.driverTransactionSummary',
                        permission: 'view-driver-transaction-summaries'
                    }
                ]
            },
            {
                title: 'menu.delivery',
                children: [
                    // {
                    //     path: '/admin/reports/packages-driver-histories',
                    //     title: 'menu.packagesDriverHistory',
                    //     permission: 'view-driver-transaction-summaries'
                    // },
                    {
                        path: '/admin/reports/shop-list',
                        title: 'menu.shopList'
                    },
                    {
                        path: '/admin/reports/shop-performances',
                        title: 'menu.shopPerformance'
                    },
                    {
                        path: '/admin/reports/driver-lists',
                        title: 'menu.driverList'
                    },
                    {
                        path: '/admin/reports/agent-lists',
                        title: 'menu.agentList'
                    },
                    {
                        path: '/admin/reports/delivery-by-statuses',
                        title: 'menu.deliveryByStatus'
                    }
                ]
            },
            {
                title: 'menu.inventory',
                children: [
                    {
                        path: '/admin/reports/product-list',
                        title: 'menu.productList'
                    },
                    {
                        path: '/admin/reports/stock-in-list',
                        title: 'menu.stockInList'
                    },
                    {
                        path: '/admin/reports/stock-order-list',
                        title: 'menu.stockOrderList'
                    },
                    {
                        path: '/admin/reports/inventory-list',
                        title: 'menu.inventoryList'
                    }
                ]
            }
        ]
    },
    {
        icon: 'fas fa-users',
        title: 'menu.security',
        children: [
            {
                path: '/admin/roles',
                title: 'menu.role',
                permission: 'view-roles'
            },
            {
                path: '/admin/users',
                title: 'menu.user',
                permission: 'view-users'
            },
            {
                path: '/admin/security/history-logs',
                title: 'menu.historyLog',
                permission: 'view-history-logs'
            }
        ]
    }
]
export default sidebarMenu
</script>
