import httpClient from '@/http-client'

export const get = (options = {}) => {
  return httpClient
    .get('/api/company-compensates/', options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}


export const store = data => {
  return httpClient
    .post('/api/company-compensates/', data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const driverClearCash = (options = {}) => {
  return httpClient
    .get(`/api/company-compensates/driver-clear-cashes`, options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const toPdf = id => {
  return httpClient
    .get(`/api/company-compensates/${id}/pdf`)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}


export default { get, store, driverClearCash, toPdf }